<template>
  <v-container fluid>
    <transition-group appear name="cell" class="row align-center justify-center">
      <slot />
      <v-col v-for="item in list" :key="item.id" cols="12" sm="6" md="4" class="cell-item">
        <v-card class="mx-auto" max-width="400">
          <v-img max-height="200px" :src="item.imageSrc" />
          <!--          <v-card-text>-->
          <!--            <router-link-->
          <!--              :to="`/author/${item.author}`"-->
          <!--              tag="h3"-->
          <!--              class="cursorPointer"-->
          <!--              v-text="item.authorName"-->
          <!--            />-->
          <!--          </v-card-text>-->
          <v-card-text
            v-if="item.shortDescription"
            class="text-justify"
            v-text="item.shortDescription"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn text color="orange" @click="createPath(item.id)">
              Погрузиться
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </transition-group>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ListComponent',
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      loading: state => state.shared.loading
    })
  },
  methods: {
    createPath(id) {
      this.$emit('createPath', id)
    }
  }
}
</script>

<style scoped lang="stylus">
.cell-item {
  transition: all 1s;
}
.cell-enter, .cell-leave-to
{
  opacity: 0;
}
.cell-leave-active {
  position: absolute;
}
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
